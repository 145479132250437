import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@/assets/css/reset.css'

import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'

import { createPinia } from 'pinia'
//引入图片懒加载插件
import Lazyload from "vue3-lazyload";

// import installElementPlus from './plugins/element'
const app =createApp(App)

//注册插件
app.use(Lazyload, {
    // loading: "@/assets/images/logo.png",//可以指定加载中的图像
    // error: "@/assets/images/logo.png",//可以指定加载失败的图像
 });
app.use(createPinia())
// installElementPlus(app)
app.use(router);
app.use(ElementPlus)
app.mount('#app')
// router.js
import { createRouter, createWebHistory } from 'vue-router';
// import Vue from "vue";


// 引入组件
// import home from '../pages/home/home.vue'
// 定义路由
const routes = [
  {
    path: '/', 
    component:  () => import('../pages/navpage'),  // 关于智充
    redirect: '/navpage/home',
    children:[
      // 首页
      { path: '/navpage/home', component: () => import('../pages/navpage/home/home.vue') },
      // 关于智充
      { path: '/navpage/briefIntro', component: () => import('../pages/navpage/briefIntro/index.vue') },
      { path: '/navpage/develop', component: () => import('../pages/navpage/develop/index.vue') },
      { path: '/navpage/enterprise', component: () => import('../pages/navpage/enterprise/index.vue') },
      { path: '/navpage/join', component: () => import('../pages/navpage/join/index.vue') },
      // 集团产业
      { path: '/navpage/technology', component: () => import('../pages/navpage/technology/index.vue') },
      { path: '/navpage/project', component: () => import('../pages/navpage/project/index.vue') },
      { path: '/navpage/manufacture', component: () => import('../pages/navpage/manufacture/index.vue') },
      { path: '/navpage/construction', component: () => import('../pages/navpage/construction/index.vue') },
      { path: '/navpage/operations', component: () => import('../pages/navpage/operations/index.vue') },
      // 产品中心
      { path: '/navpage/directc', component: () => import('../pages/navpage/directc/index.vue') },
      { path: '/navpage/exchange', component: () => import('../pages/navpage/exchange/index.vue') },
      { path: '/navpage/heapCharging', component: () => import('../pages/navpage/heapCharging/index.vue') },
      { path: '/navpage/energyStorage', component: () => import('../pages/navpage/energyStorage/index.vue') },
      { path: '/navpage/otherSeries', component: () => import('../pages/navpage/otherSeries/index.vue') },
      // 产品中心下的详情页
      { path: '/navpage/directc/directcDetail', component: () => import('../pages/navpage/directc/directcDetail/index.vue') },
      { path: '/navpage/exchange/exchangeDetail', component: () => import('../pages/navpage/exchange/exchangeDetail/index.vue') },
      { path: '/navpage/heapCharging/hChargDetail', component: () => import('../pages/navpage/heapCharging/hChargDetail/index.vue') },
      { path: '/navpage/otherSeries/otherDetail', component: () => import('../pages/navpage/otherSeries/otherDetail/index.vue') },
      { path: '/navpage/energyStorage/energyStorDetail', component: () => import('../pages/navpage/energyStorage/energyStorDetail/index.vue') },
      
      // 新闻资讯
      { path: '/navpage/dynamic', component: () => import('../pages/navpage/dynamic/index.vue') },
      { path: '/navpage/dynamic/517dynDetail', component: () => import('../pages/navpage/dynamic/517dynDetail/index.vue') },
      { path: '/navpage/dynamic/511dynDetail', component: () => import('../pages/navpage/dynamic/511dynDetail/index.vue') },
      { path: '/navpage/dynamic/419dynDetail', component: () => import('../pages/navpage/dynamic/419dynDetail/index.vue') },
      { path: '/navpage/dynamic/307dynDetail', component: () => import('../pages/navpage/dynamic/307dynDetail/index.vue') },
      { path: '/navpage/dynamic/119dynDetail', component: () => import('../pages/navpage/dynamic/119dynDetail/index.vue') },
      { path: '/navpage/dynamic/906dynDetail', component: () => import('../pages/navpage/dynamic/906dynDetail/index.vue') },

      { path: '/navpage/industry', component: () => import('../pages/navpage/industry/index.vue') },

      { path: '/navpage/cooperation', component: () => import('../pages/navpage/cooperation/index.vue') },
      { path: '/navpage/advertisement', component: () => import('../pages/navpage/advertisement/index.vue') },
      // 法律条款
      { path: '/navpage/legalProvisions', component: () => import('../pages/navpage/legalProvisions/index.vue') },
      // 联系我们
      { path: '/navpage/contactUs', component: () => import('../pages/navpage/contactUs/index.vue') },
      
      
    ]
  },
  // {
  //   path: '/dynDetail', 
  //   component:  () => import('../pages/dynDetail/index.vue'),  // 信
  // },
  {
    // 给员工的一封信
    path: '/letter', 
    component:  () => import('../pages/letter/index.vue'),  // 信
  },
];

// let myPush = VueRouter.prototype.push

// // 封装push
// VueRouter.prototype.push = function (location, resolved, rejected) {
//     if (resolved == undefined && rejected == undefined) {
//         // 这里的this需要改变因为mypush的this为window我们应该将他的this变为实例对象
//         return myPush.call(this, location).catch(() => { });
//     } else {
//         return myPush.call(this, location, resolved, rejected);
//     }
// };

// let myReplace = VueRouter.prototype.replace;
// // 封装replace
// VueRouter.prototype.replace = function (location, resolved, rejected) {
//     if (resolved == undefined && rejected == undefined) {
//         return myReplace.call(this, location).catch(() => { });
//     } else {
//         return myReplace(this, location, resolved, rejected);
//     }
// };

// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});
// console.log(router,'router')
// 确保路由跳转发生错误时，重定向到首页
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/') 
  } else {
    next() 
  } 
})
export default router;


